import { Disclosure, Transition } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import gfm from 'remark-gfm';

export default function FAQ() {
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();

    const fetchFaqs = async () =>
      fetch(`/data/${i18n.language}/faq.json`, {
        signal: controller.signal,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          navigate('/404');
          return [];
        })
        .then((data) => setFaqs(data));

    if (faqs.length === 0) {
      fetchFaqs();
    }

    return () => {
      controller.abort();
    };
  }, [faqs.length, i18n.language, navigate]);

  return (
    <div className="relative isolate overflow-hidden pt-14">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
          <div className="max-w-2xl lg:mx-auto lg:text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-congress-blue-900 sm:text-4xl">
              <Trans t={t}>Ofte stilte spørsmål</Trans>
            </h2>
            <p className="mt-4 text-gray-500"></p>
          </div>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full cursor-pointer items-start justify-between text-left text-gray-400 hover:text-azure-500">
                        <span className="font-medium text-congress-blue-900">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronLeftIcon
                            className={` ${
                              open ? '-rotate-90' : 'rotate-0'
                            } h-6 w-6 transition delay-100 ease-in-out`}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Transition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="h-0 opacity-0"
                      enterTo="h-fit opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="h-fit opacity-100"
                      leaveTo="h-0 opacity-0"
                    >
                      <Disclosure.Panel
                        static
                        as="dd"
                        className="prose prose-sm prose-congress-blue mt-2 max-w-none pr-12 prose-p:text-gray-600"
                      >
                        <ReactMarkdown
                          linkTarget="_blank"
                          remarkPlugins={[gfm]}
                        >
                          {faq.answer}
                        </ReactMarkdown>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
