import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

export default function MarkdownToHtml({ children }: { children: string }) {
  return (
    <div className="prose prose-sm prose-congress-blue !opacity-100 prose-strong:text-gray-700 text-gray-600 prose-p:text-gray-600 prose:text-gray-600 rounded-md border border-gray-200 p-4 prose-headings:text-base">
      <ReactMarkdown linkTarget="_blank" remarkPlugins={[gfm]}>
        {children}
      </ReactMarkdown>
    </div>
  );
}

// FAQ har dessa Tailwind-klasser:
// "prose prose-sm prose-congress-blue prose-p:text-gray-500"